import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import bidService from "../../../services/bid-service";
import { addBid } from "../../../slices/bidSlice";
import { addBidCount } from "../../../slices/projectSlice";

type FormData = {
  bidAmount: number;
  bidHours: number;
  proposalMessage: string;
};

const PlaceBid = () => {
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((store: any) => store.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onsubmit = async (data: FormData) => {
    if (!user) {
      setError("Please login to place a bid");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
      return;
    }
    bidService
      .createBid({
        projectId: id ? parseInt(id) : 0,
        user: user,
        bidAmount: data.bidAmount,
        bidDescription: data.proposalMessage,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            addBid({
              ...response.data,
            })
          );
          dispatch(
            addBidCount({
              ...response.data,
            })
          );
          setError("Bid has been placed successfully");
          setTimeout(() => {
            navigate("/posts");
          }, 2000); // Redirects after 2 seconds
        }
      });
  };

  return (
    <div className="flex flex-col">
      <p className="text-red-500">{error}</p>
      <h2 className="font-bold text-lg mb-4 text-black mt-12 text-center">
        Place a Bid on this project
      </h2>
      <form onSubmit={handleSubmit(onsubmit)} method="POST">
        <div className="flex">
          <div className="items-center w-48 m-4">
            <label htmlFor="bidAmount" className="block text-gray-700 mx-2">
              Bid Amount* <span className="text-sm">(hourly $)</span>
            </label>
            <input
              {...register("bidAmount", {
                required: {
                  value: true,
                  message: "bid amount per hour is required",
                },
              })}
              type="number"
              id="bidAmount"
              className="block w-full p-2 border rounded mt-2 mb-4"
            />
            {errors?.bidAmount && (
              <p className="text-red-500 text-sm italic">
                {errors.bidAmount.message}
              </p>
            )}
          </div>
          <div className="items-center w-48 m-4">
            <label htmlFor="bidHours" className="block text-gray-700 mx-2">
              Bid Hours*<span className="text-sm">(max hours)</span>
            </label>
            <input
              {...register("bidHours", {
                required: {
                  value: true,
                  message: "no of hours are required",
                },
              })}
              type="number"
              id="bidHours"
              className="block w-full p-2 border rounded mt-2 mb-4"
            />
            {errors?.bidHours && (
              <p className="text-red-500 text-sm italic">
                {errors.bidHours.message}
              </p>
            )}
          </div>
        </div>
        <div className="items-center mb-4 w-9/10 h-3/6 m-4">
          <label htmlFor="proposalMessage" className="block text-gray-700 mx-2">
            Proposal message* <span className="text-sm">(min 100 words)</span>
          </label>
          <textarea
            {...register("proposalMessage", {
              required: {
                value: true,
                message: "proposal message is required to place a bid",
              },
              minLength: {
                value: 100,
                message: "message should be atleast 100 characters long",
              },
            })}
            id="proposalMessage"
            className="w-full h-64 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
          {errors?.proposalMessage && (
            <p className="text-red-500 text-sm italic">
              {errors.proposalMessage.message}
            </p>
          )}
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 justify-center flex rounded hover:bg-blue-700 mt-2"
          >
            Place my Bid
          </button>
        </div>
      </form>
    </div>
  );
};

export default PlaceBid;
