const stats = [
  {
    id: 1,
    name: "Registered Freelancers",
    value: "70,000",
  },
  {
    id: 2,
    name: "Ongoing Projects",
    value: "23,000",
  },
  {
    id: 3,
    name: "New users added this month",
    value: "4,000",
  },
];

export default function Stats() {
  return (
    <div className="bg-black text-gray-300 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-300 sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
