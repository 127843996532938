import apiClient from "./api-client";

export interface Project {
  id?: number;
  name: string;
  location: string;
  timezone: string;
  duration: string;
  technology: string;
  billingRate: string;
  description: string;
  message: string;
  userId: string;
  projectType: string;
  bidCount: number;
}

class ProjectService {
  getProjects() {
    return apiClient.get<Project[]>("project/");
  }

  deleteProject(id: number) {
    return apiClient.delete("/project/" + id);
  }

  createProject(project: Project) {
    return apiClient.post("/project/", project);
  }

  updateProject(project: Project) {
    return apiClient.patch("/project/" + project.id, project);
  }
}

export default new ProjectService();
