import { createSlice } from "@reduxjs/toolkit";
import { Bid } from "../services/bid-service";

const bidSlice = createSlice({
  name: "bid",
  initialState: {
    bids: [],
  } as { bids: Bid[] },
  reducers: {
    addBid: (state, action) => {
      if (action.payload) {
        state.bids.unshift(action.payload);
      }
    },
    fetchBids: (state, action) => {
      state.bids = action.payload;
    },
  },
});

export default bidSlice.reducer;
export const { addBid, fetchBids } = bidSlice.actions;
