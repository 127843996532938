import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import projectService, { Project } from "../services/project-service";
import { User } from "../services/user-service";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [] as Project[],
  },
  reducers: {
    addProject: (state, action) => {
      return action.payload;
    },
    fetchProject: (state, action) => {
      state.projects = action.payload;
    },
    removeProjects: (state) => {
      state.projects.length = 0;
    },
    addBidCount: (state, action) => {
      const project = state.projects.find(
        (project) => project.id === +action.payload.projectId
      );
      if (project) {
        project.bidCount++;
      }
    },
  },
});

export default projectSlice.reducer;
export const { addProject, fetchProject, addBidCount } = projectSlice.actions;
