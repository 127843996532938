import React from "react";
import AddExperience from "./AddExperience";
import userService from "../../../services/user-service";
import { updateUserExperience } from "../../../slices/userSlice";
import { useDispatch } from "react-redux";

const Experience = ({ user }: any) => {
  const dispatch = useDispatch();
  const [showExperienceForm, setShowExperienceForm] = React.useState(true);
  const handleCancel = () => {
    setShowExperienceForm(!showExperienceForm);
  };

  const handleClick = () => {
    setShowExperienceForm(!showExperienceForm);
  };

  const handleSave = () => {
    setShowExperienceForm(!showExperienceForm);
  };

  const handleEdit = async (experience: any) => {
    try {
      const result = await userService.updateExperience(experience);
      if (result.status === 200) {
        alert("Profile updated successfully");
        dispatch(updateUserExperience(result.data.experiences));
      } else {
        alert("Profile update failed");
      }
    } catch (err) {
      alert("Profile update failed" + err);
    }
  };

  const handleDelete = async (experience: any) => {
    try {
      const result = await userService.deleteExperience(experience);
      if (result.status === 200) {
        alert("Profile updated successfully");
        dispatch(updateUserExperience(result.data.experiences));
      } else {
        alert("Profile update failed");
      }
    } catch (err) {
      alert("Profile update failed" + err);
    }
  };

  return (
    <div className="h-full bg-white w-full text-black">
      <div className="flex justify-between m-2">
        <h1 className="text-xl m-2 text-center font-bold">Experience</h1>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded align-right"
          onClick={handleClick}
        >
          Add Experience
        </button>
      </div>
      {!showExperienceForm && (
        <AddExperience
          handleCancel={handleCancel}
          user={user}
          handleSave={handleSave}
        />
      )}

      {showExperienceForm &&
        user &&
        user.experiences &&
        user.experiences.map((experience: any, index: number) => (
          <div
            key={index}
            className="p-4 bg-white shadow rounded-lg mb-4 w-2/3"
          >
            <div className="flex justify-between">
              <h3 className="text-lg font-bold text-gray-900">
                {experience.title}
              </h3>
              <div className="flex cursor-pointer">
                <svg
                  onClick={() => handleDelete(experience)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </div>
            </div>
            <p className="text-sm font-bold text-gray-900">
              {experience.company}
            </p>
            <p className="text-sm text-gray-500">
              {experience.startMonth}
              {experience.startYear} - {experience.endMonth}
              {experience.endYear}
            </p>
            <p className="text-sm text-gray-600">{experience.summary}</p>
          </div>
        ))}
    </div>
  );
};

export default Experience;
