import { AxiosRequestConfig } from "axios";
import apiClient from "./api-client";

export interface User {
  id?: number;
  userName: string | null;
  password?: string | null;
  role: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePic?: string | null;
  userDetails?: UserDetails | any;
  experiences?: Experience[] | null;
}

export interface UserDetails {
  userName: string;
  headline: string;
  description: string;
  pictureUrl: string;
}

export interface Experience {
  company: string;
  title: string;
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  summary: string;
  isCurrentCompany: boolean;
  userName?: string;
}

class UserService {
  getUsers() {
    return apiClient.get<User[]>("users/");
  }

  deleteUser(id: number) {
    return apiClient.delete("/users/" + id);
  }

  createUser(user: User) {
    return apiClient.post("/users/save", user);
  }

  loginUser(user: any) {
    return apiClient.post("/users/login", user);
  }

  updateUser(user: User) {
    return apiClient.patch("/users/" + user.id, user);
  }

  updateUserDetails(userDetails: UserDetails) {
    return apiClient.post("/user_details/save", userDetails);
  }

  saveExperience(experience: Experience) {
    return apiClient.post("/experience/save", experience);
  }

  deleteExperience(experience: Experience) {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: "/experience/delete",
      data: experience,
    };
    return apiClient.delete("/experience/delete", config);
  }

  updateExperience(experience: Experience) {
    const config: AxiosRequestConfig = {
      method: "put",
      url: "/experience/update",
      data: experience,
    };
    return apiClient.put("/experience/update", config);
  }

  fetchUserDetails(userName: string) {
    return apiClient.get<User>("/users/" + userName);
  }

  uploadImage(formData: FormData) {
    return apiClient.post("/files/upload", formData);
  }
}

export default new UserService();
