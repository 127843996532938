// SidePanel.js
import React, { useState } from "react";

const SidePanel: React.FC<any> = ({
  open,
  onClose,
  applyFilter,
  clearFilter,
}) => {
  const [selectedTech, setSelectedTech] = useState("");
  const [projectType, setProjectType] = useState("");
  const [minBudget, setMinBudget] = useState<number>();
  const [maxBudget, setMaxBudget] = useState<number>();
  const [minDuration, setMinDuration] = useState<number>();
  const [maxDuration, setMaxDuration] = useState<number>();

  const handleMinDurationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMinDuration(+event.target.value);
    applyFilter("duration", { min: +event.target.value, max: maxDuration });
  };

  const handleMaxDurationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxDuration(+event.target.value);
    applyFilter("duration", { min: minDuration, max: +event.target.value });
  };

  const handleMinBudgetChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMinBudget(+event.target.value);
    applyFilter("budget", { min: +event.target.value, max: maxBudget });
  };

  const handleMaxBudgetChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxBudget(+event.target.value);
    applyFilter("budget", { min: minBudget, max: +event.target.value });
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectType(event.target.value);
    applyFilter("isHourlyRateChecked", event.target.checked);
  };

  const handleSelectChange = (event: any) => {
    setSelectedTech(event.target.value);
    applyFilter("selectedTech", event.target.value);
  };
  const clearSelection = () => {
    setProjectType("");
    setMaxDuration(0);
    setMinDuration(0);
    setMaxBudget(0);
    setMinBudget(0);
    setSelectedTech("All");
  };
  return (
    <div
      className={` fixed inset-y-0 left-0 z-10 bg-gray-200 w-64 py-4 px-6 transition duration-300 transform ${
        open ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <form className="rounded-s-md">
        <h2 className="text-xl font-semibold mb-4 mt-24">Filters</h2>
        <div className="mb-4 cursor-pointer">
          <div className="flex justify-between">
            <h3 className="font-semibold mb-2 gap-2 ">Project Types</h3>
            <button
              className="bg-blue-500 text-white text-xs px-2 py-1 rounded cursor-pointer"
              onClick={clearSelection}
            >
              Clear
            </button>
          </div>
          <label className="flex items-center space-x-2 mb-1">
            <input
              type="checkbox"
              value="hourly"
              className="form-checkbox h-6 w-6 text-blue-600 mb-1 cursor-pointer"
              checked={projectType === "hourly"}
              onChange={handleCheckChange}
            />
            <span>Hourly Rate</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              value={"fixedPrice"}
              className="form-checkbox h-6 w-6 text-blue-600 cursor-pointer"
              checked={projectType === "fixedPrice"}
              onChange={handleCheckChange}
            />
            <span>Fixed Price</span>
          </label>
        </div>
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Duration</h3>
          <label>
            Min:
            <input
              type="number"
              className="form-input block w-full py-1"
              onChange={handleMinDurationChange}
              value={minDuration}
            />
          </label>
          <label>
            Max:
            <input
              type="number"
              className="form-input block w-full py-1"
              onChange={handleMaxDurationChange}
              value={maxDuration}
            />
          </label>
        </div>
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Budget</h3>
          <label>
            Min:
            <input
              type="number"
              className="form-input block w-full py-1"
              onChange={handleMinBudgetChange}
              value={minBudget}
            />
          </label>
          <label>
            Max:
            <input
              type="number"
              className="form-input block w-full py-1"
              onChange={handleMaxBudgetChange}
              value={maxBudget}
            />
          </label>
        </div>
        <div className="relative">
          <select
            value={selectedTech}
            onChange={(event) => handleSelectChange(event)}
            className="w-full px-3 py-2 border cursor-pointer border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            id="grid-state"
          >
            <option value="All">Select a technology</option>
            <option value="Vue">Java</option>
            <option value="React">React</option>
            <option value="NextJs">Next Js</option>
            <option value="Angular">Angular JS</option>
            <option value="Vue">Vue JS</option>
            <option value="Aws">AWS</option>
            <option value="Azure">Microsoft Azure</option>
            <option value="GoogleCloud">Google Cloud</option>
          </select>
        </div>
      </form>
    </div>
  );
};

export default SidePanel;
