import React from "react";

export const capitalizeFirstLetter = (string: String) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

const SummaryProfile = ({
  user,
  isEditMode,
  handleTextChange,
  handletextAreaChange,
  headline,
  description,
}: any) => {
  return (
    <div className="bg-white text-black h-full w-full pr-4 pb-4">
      {" "}
      <h2 className="text-lg font-semibold text-gray-900 p-4 w-full">
        {capitalizeFirstLetter(user?.name)}
      </h2>
      {isEditMode ? (
        <input
          type="text"
          className="border border-gray-300 w-full mr-12 pr-12"
          placeholder="Tell us about yourself... "
          value={headline}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(event)
          }
        />
      ) : (
        <p className="text-md text-gray-600 text-left pl-4 w-full">
          {headline ?? "Tell us about yourself... "}
        </p>
      )}
      <h2 className="text-lg font-semibold text-gray-900 mt-4 p-4 w-full">
        Description
      </h2>
      {isEditMode ? (
        <textarea
          className="border border-gray-300 h-48 w-full self-start align-top"
          placeholder="Share your experience, skills, and what you are looking for ? "
          value={description}
          onChange={handletextAreaChange}
        />
      ) : (
        <p className="text-md  text-gray-600  pr-12 pl-4 pb-24 w-full">
          {description ??
            "Share your experience, skills, and what you are looking for ? "}
        </p>
      )}
    </div>
  );
};

export default SummaryProfile;
