import React, { useState } from "react";

const SearchBar = ({ onSearch }: any) => {
  const [search, setSearch] = useState("");

  const handleChange = (event: any) => {
    setSearch(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <div className="flex">
      <input
        type="text"
        value={search}
        onChange={handleChange}
        placeholder="Search for projects..."
        className="shadow appearance-none border rounded w-9/10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
  );
};

export default SearchBar;
