// Footer.js
import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom if you're using React Router

const Footer = () => (
  <footer className="bg-black text-white p-4 ">
    <div className="container mx-auto flex justify-between items-center">
      <p>© 2024 The Developers World. All rights reserved.</p>
      <div className="flex space-x-4">
        <Link to="/terms-of-service" className="hover:underline">
          Terms of Service
        </Link>
        <Link to="/privacy-policy" className="hover:underline">
          Privacy Policy
        </Link>
      </div>
    </div>
  </footer>
);

export default Footer;
