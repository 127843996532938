import { createSlice } from "@reduxjs/toolkit";
import { User } from "../services/user-service";

const initialState: User = {
  userName: null,
  role: null,
  firstName: null,
  lastName: null,
  profilePic: null,
  userDetails: [],
  experiences: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      return action.payload;
    },
    removeUser: (state, action) => initialState,
    updateUserProfile: (state, action) => {
      state.userDetails = action.payload.userDetails;
      state.experiences = action.payload.experiences;
      state.profilePic = action.payload.profilePic;
    },
    updateUserExperience: (state, action) => {
      state.experiences = action.payload;
    },
    updateUserProfileImage: (state, action) => {
      state.profilePic = action.payload;
    },
  },
});

export default userSlice.reducer;
export const {
  addUser,
  removeUser,
  updateUserProfile,
  updateUserExperience,
  updateUserProfileImage,
} = userSlice.actions;
