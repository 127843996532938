import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import appStore from "./utils/appStore";
import { useLayoutEffect, useRef } from "react";
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Login from "./components/login/Login";
import Post from "./components/post/Post";
import PostDescription from "./components/post/description/PostDescription";
import Register from "./components/register/Register";
import PostProject from "./components/post/project/PostProject";
import ContactSales from "./components/contact-sales/ContactSales";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import TermsOfService from "./components/terms-of-service/TermsOfService";
import ProfilePage from "./components/profile-page/ProfilePage";

function App() {
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const statsRef = useRef(null);
  const testimonialsRef = useRef(null);

  const linkClick = (item) => {
    let ref;
    switch (item.name) {
      case "Product":
        ref = featuresRef;
        break;
      case "Services":
        ref = pricingRef;
        break;
      case "Company":
        ref = statsRef;
        break;
      case "Features":
        ref = featuresRef;
        break;
      default:
        return;
    }

    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <GoogleOAuthProvider clientId="45515671882-n5aetk3tjf4aep7hk5ors30geb9gu045.apps.googleusercontent.com">
        <Provider store={appStore}>
          <Router>
            <Header linkClick={linkClick} />
            <Routes>
              <Route
                path="/"
                element={
                  <Body
                    featuresRef={featuresRef}
                    pricingRef={pricingRef}
                    statsRef={statsRef}
                    testimonialsRef={testimonialsRef}
                  />
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/posts" element={<Post />} />
              <Route path="/postProject" element={<PostProject />} />
              <Route path="/post/:id" element={<PostDescription />} />
              <Route path="/contact-us" element={<ContactSales />} />
              <Route path="/profile-page" element={<ProfilePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
          </Router>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
