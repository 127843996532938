import apiClient from "./api-client";

export interface ContactUs {
  id?: number;
  firstName: string;
  lastName?: string;
  company?: string;
  email: string;
  phoneNumber: string;
  message?: string;
}

class ContactUsService {
  fetch() {
    return apiClient.get<ContactUs[]>("contactus/");
  }

  save(contactus: ContactUs) {
    return apiClient.post("/contactus/save", contactus);
  }
}

export default new ContactUsService();
