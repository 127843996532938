import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import projectService from "../../../services/project-service";
import { fetchProject } from "../../../slices/projectSlice";
import PlaceBid from "../bid/PlaceBid";
import { useState } from "react";
import "./PostDescription.css";

const PostDescription = () => {
  const [isBidsVisible, setIsBidsVisible] = useState<boolean>(true);
  const dispatch = useDispatch();
  let projects = useSelector((store: any) => store.project.projects);
  const { id } = useParams();

  const handleProposals = () => {
    setIsBidsVisible(false);
  };

  const handleBids = () => {
    setIsBidsVisible(true);
  };

  const fetchProjects = async () => {
    const projects = await projectService.getProjects();
    dispatch(fetchProject(projects.data));
  };

  if (!projects || projects.length === 0) {
    fetchProjects();
  }

  const project = projects?.find((project: any) => project.id == id);

  if (project) {
    return (
      <div className="flex flex-col bg-gray-200 h-full">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-6 h-114">
          <div className="mb-0 sm:col-span-6 md:col-span-3">
            <div className="bg-white text-black p-6 rounded-lg shadow-lg mt-24 ml-12 mr-12 sm:mr-12">
              <h2 className="font-bold text-2xl mb-3 text-blue-600 text-center">
                Project Description
              </h2>
              <div className="mb-6 text-center">
                <div className="font-bold text-lg">{project.name}</div>
              </div>
              <div className="p-4 rounded">
                <h2 className="font-bold text-lg mb-4 text-blue-600">
                  Project Details
                </h2>
                <p className="text-black-300 leading-relaxed">
                  {project.description}
                </p>
              </div>
              <div className="p-4 rounded">
                <h2 className="font-bold text-lg mb-4 text-blue-600">
                  Technology Needed
                </h2>
                <p className="text-black-300 leading-relaxed">
                  {project.technology}
                </p>
              </div>
              <div className="p-4 rounded ">
                <h2 className="font-bold text-lg mb-4 text-blue-600">Budget</h2>
                <p>
                  {project.projectType ? project.projectType : "Hourly"}:{" "}
                  {project.billingRate}$ /hr
                </p>
                <p>Max Duration: {project.duration} hours</p>
              </div>
              <div className="p-4 rounded ">
                <h2 className="font-bold text-lg mb-4 text-blue-600">
                  Location
                </h2>
                <p className="text-black-300 leading-relaxed">
                  {project.location} {project.timezone} timezone
                </p>
              </div>
            </div>
          </div>
          {isBidsVisible && (
            <div className="mb-0 sm:col-span-6 md:col-span-3">
              <div className="mt-24 mr-12 bg-white rounded-lg mb-40 sm:col-span-6 md:col-span-3 sm:ml-12 ml-12">
                <PlaceBid />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};
export default PostDescription;
