import { Link, useNavigate } from "react-router-dom";
import { addUser, updateUserProfile } from "../../slices/userSlice";
import userService from "../../services/user-service";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

type FormData = {
  email: string;
  password: string;
};

export const Login = () => {
  const [error, setError] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onsubmit = async (data: FormData) => {
    try {
      const result = await userService.loginUser({
        userName: data.email,
        password: data.password,
      });
      if (result.status === 200) {
        localStorage.setItem("user", JSON.stringify(result.data.user));
        dispatch(
          addUser({
            ...result.data.user,
          })
        );
        navigate("/");
      }
    } catch (err: any) {
      setError(err.response.data.message);
      navigate("/login");
    }
  };

  return (
    <div className="flex min-h-screen  flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-black text-gray-300">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-200">
          Sign in to your account
        </h2>
      </div>
      <p className="justify-center text-red-500 font-semibold text-center">
        {error}
      </p>
      <div className="flex justify-center items-center mt-4">
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            if (credentialResponse && credentialResponse.credential) {
              let credential_response: any = jwtDecode(
                credentialResponse.credential
              );
              const result = await userService.loginUser({
                userName: credential_response.email,
                password: "Info@123",
                role: "CLIENT",
                firstName: credential_response.given_name,
                lastName: credential_response.family_name,
              });
              if (result.status === 200) {
                dispatch(
                  addUser({ ...result.data.user, ...credential_response })
                );
                localStorage.setItem(
                  "user",
                  JSON.stringify(credential_response)
                );
                const user = await userService.fetchUserDetails(
                  credential_response?.email
                );
                dispatch(updateUserProfile(user.data));
                navigate("/");
              }
            }
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={handleSubmit(onsubmit)}
          method="POST"
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-300"
            >
              Email address | username
            </label>
            <div className="mt-2">
              <input
                {...register("email", {
                  required: "Email is required",
                })}
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-300"
              >
                Password
              </label>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-semibold text-blue-500 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                {...register("password", {
                  required: {
                    value: true,
                    message: "password is required",
                  },
                  minLength: {
                    value: 6,
                    message: "min length is 6",
                  },
                })}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </form>
        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{" "}
          <Link
            to="/register"
            className="font-semibold leading-6 text-blue-600 hover:text-indigo-500"
          >
            Register yourself
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
