import { Fragment, useEffect, useState } from "react";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import userService from "../../services/user-service";

import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  ChevronRightIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  BellIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addUser, removeUser, updateUserProfile } from "../../slices/userSlice";
import {
  Dialog,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { capitalizeFirstLetter } from "../profile-page/summary-profile/SummaryProfile";
const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Services", href: "#" },
  { name: "Company", href: "#" },
];

const userNavigation = [{ name: "Your Profile", href: "/profile-page" }];

const Header = (props: any) => {
  const navigate = useNavigate();
  const user = useSelector((store: any) => store.user);
  const dispatch = useDispatch();
  const location = useLocation();

  const fetchUserDetails = async () => {
    if (!user || (user && !user.email && localStorage.getItem("user"))) {
      let storedValue: any = localStorage.getItem("user");
      try {
        let credential_response = JSON.parse(storedValue);
        dispatch(addUser(credential_response));
        const user = await userService.fetchUserDetails(
          credential_response?.email
        );
        dispatch(updateUserProfile(user.data));
      } catch (error) {
        console.error("Error parsing JSON from local storage", error);
      }
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleSignIn = () => {
    setMobileMenuOpen(false);
    navigate("/login");
  };

  const handleSignOut = () => {
    setMobileMenuOpen(false);
    localStorage.removeItem("user");
    dispatch(removeUser({}));
    navigate("/");
  };

  const linkClick = (item: any) => {
    setMobileMenuOpen(false);
    props.linkClick(item);
  };

  const handleClick = () => {
    setMobileMenuOpen(false);
    if (!user) {
      alert("Please login to post a project");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
      return;
    }
    navigate("/postProject");
  };
  const solutions = [
    {
      name: "My Profile",
      description: "",
      href: "/profile-page",
    },
  ];

  const showSignInOut = () => {
    if (!user && localStorage.getItem("user")) {
      let storedValue: any = localStorage.getItem("user");
      try {
        let credential_response = JSON.parse(storedValue);
        dispatch(addUser(credential_response));
      } catch (error) {
        console.error("Error parsing JSON from local storage", error);
      }
    }
    return user && user.email ? (
      <div className="flex">
        <div className="flex m-1 p-2 cursor-pointer">
          {(user.given_name || user.firstName) &&
            `Hi ${
              user?.given_name
                ? capitalizeFirstLetter(user.given_name)
                : capitalizeFirstLetter(user.firstName)
            } `}
        </div>
        <div className="group inline-block relative cursor-pointer">
          <img
            alt=""
            src={user.picture ? user.picture : "logo.png"}
            className="h-12 w-12 rounded-full m-1"
          />
          <div className="opacity-0 group-hover:opacity-100 absolute left-0 w-48 mt-2 p-2 bg-white text-black shadow-lg rounded transition-opacity duration-200 delay-150">
            {userNavigation.map((item) => (
              <Link
                to={item.href}
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                onClick={() => setMobileMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        <button
          className="mx-1 p-2 font-bold rounded-lg w-24 h-12 cursor-pointer"
          onClick={handleSignOut}
        >
          Sign out
        </button>
      </div>
    ) : (
      <button className="px-1" onClick={handleSignIn}>
        Sign In
      </button>
    );
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="fixed w-full inset-x-0 top-0 z-50 text-white bg-black">
      <nav
        className="flex items-center justify-between p-6 lg:px-8 sticky text-white bg-black shadow-md"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 text-white">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">The Developers World</span>
            <img
              className="w-12 h-12 rounded-full shadow-lg"
              src="logo.png"
              alt=""
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-200"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {location.pathname === "/" &&
            navigation.map((item) => (
              <a
                onClick={() => linkClick(item)}
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {location.pathname == "/" && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleClick}
            >
              Post a project
            </button>
          )}
          {showSignInOut()}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black text-gray-200 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-300"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {location.pathname === "/" &&
                  navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-50"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </a>
                  ))}
              </div>
              {location.pathname == "/" && (
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 sm:h-12
                  sm:px-2 md:px-3 lg:px-8 xl:px-12 text-sm text-nowrap w-4/12"
                  onClick={handleClick}
                >
                  Post a project
                </button>
              )}
              <div className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-50">
                {showSignInOut()}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
