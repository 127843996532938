import React, { useEffect, useState } from "react";
import useProjects from "../../hooks/useProjects";
import { PostCard } from "./card/PostCard";
import SidePanel from "./side-panel/SidePanel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import projectService from "../../services/project-service";
import { addProject, fetchProject } from "../../slices/projectSlice";
import Header from "../header/Header";
import bidService from "../../services/bid-service";
import { fetchBids } from "../../slices/bidSlice";
import "./Post.css";

const Post = () => {
  const [filteredProjects, setFilteredProjects] = React.useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // subscribing to the store using a selector
  let projects = useSelector((store: any) => store.project.projects);
  let bids = useSelector((store: any) => store.bid.bids);
  let user = useSelector((store: any) => store.user);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjects = async () => {
    const projects = await projectService.getProjects();
    setFilteredProjects(projects.data);
    dispatch(fetchProject(projects.data));
    setIsLoading(false);
  };

  const fetchBid = async () => {
    const bids = await bidService.getBids();
    dispatch(fetchBids(bids.data));
  };

  useEffect(() => {
    setIsLoading(true);
    if (!projects || projects.length === 0) {
      fetchProjects();
    } else {
      setFilteredProjects(projects);
      setIsLoading(false);
    }

    if (!bids || bids.length === 0) {
      fetchBid();
    }
  }, []);

  const filterHandler = (filterType: any, filterValue: any) => {
    if (projects && projects.length > 0) {
      switch (filterType) {
        case "isHourlyRateChecked":
          setFilteredProjects(
            projects.filter((project: any) => project.projectType === "hourly")
          );
          break;
        case "isFixedPriceChecked":
          setFilteredProjects(
            projects.filter((project: any) => project.projectType !== "hourly")
          );
          break;
        case "duration":
          setFilteredProjects(
            projects.filter(
              (project: any) =>
                (project.duration >= filterValue.min &&
                  project.duration <= filterValue.max) ||
                (filterValue.min === 0 && filterValue.max === 0)
            )
          );
          break;
        case "budget":
          setFilteredProjects(
            projects.filter(
              (project: any) =>
                (project.billingRate >= filterValue.min &&
                  project.billingRate <= filterValue.max) ||
                (filterValue.min === 0 && filterValue.max === 0)
            )
          );
          break;
        case "selectedTech":
          setFilteredProjects(
            projects.filter(
              (project: any) =>
                project?.technology?.toLowerCase() ===
                  filterValue?.toLowerCase() || filterValue === "All"
            )
          );
          break;
        default:
          setFilteredProjects(projects);
      }
    }
  };

  const clearFilter = () => {
    setFilteredProjects(projects);
  };

  return (
    <div className="flex min-h-screen h-full w-full bg-black">
      <div className="p-6 w-64 bg-black h-full  sidebar">
        <SidePanel
          open={() => {}}
          onClose={() => {}}
          applyFilter={filterHandler}
          clearFilter={clearFilter}
        />
      </div>
      <div className="flex-1 p-6 h-full bg-black">
        <div className="mt-5">
          <h2 className="text-2xl font-bold mb-2 text-white text-center">
            Projects
          </h2>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center min-h-screen">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        ) : (
          projects &&
          filteredProjects.length > 0 && (
            <PostCard projects={filteredProjects} />
          )
        )}
      </div>
    </div>
  );
};

export default Post;
