import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SortDropdown from "../sort-dropdown/SortDropDown";
import SearchBar from "../search-bar/SearchBar";

export const PostCard: React.FC<any> = ({ projects }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("latest");
  const postcardsPerPage = 5;

  // Calculate the index of the first and last postcards on the current page
  const indexOfLastPostcard = currentPage * postcardsPerPage;
  const indexOfFirstPostcard = indexOfLastPostcard - postcardsPerPage;

  // Get the postcards for the current page
  let currentPostcards =
    search && search.length > 0
      ? projects
          .filter(
            (project: any) =>
              project.name?.toLowerCase().includes(search.toLowerCase()) ||
              project.technology
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              project.location?.toLowerCase().includes(search.toLowerCase())
          )
          .slice(indexOfFirstPostcard, indexOfLastPostcard)
      : projects.slice(indexOfFirstPostcard, indexOfLastPostcard);

  // Calculate based ont he sorted value
  currentPostcards =
    sort && sort.length > 0
      ? currentPostcards.sort((a: any, b: any) => {
          switch (sort) {
            case "latest":
              return b.updatedDate - a.updatedDate;
            case "highestBid":
              return b.bidCount - a.bidCount;
            case "lowestBid":
              return a.bidCount - b.bidCount;
            case "highestHours":
              return b.duration - a.duration;
            case "lowestHours":
              return a.duration - b.duration;
          }
        })
      : currentPostcards;

  const lastUpdated = (project: any) => {
    // Assuming updatedDate is a Date object representing the time of update
    let updatedDate: any = new Date(project.updatedDate); // replace with your actual date

    let currentDate: any = new Date();

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = currentDate - updatedDate;

    // Calculate the difference in minutes
    let differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);
    // Calculate the difference in hours
    let differenceInHours = Math.floor(
      differenceInMilliseconds / 1000 / 60 / 60
    );

    // Calculate the difference in days
    let differenceInDays = Math.floor(
      differenceInMilliseconds / 1000 / 60 / 60 / 24
    );

    return differenceInDays == 0
      ? `Updated ${differenceInHours} hours ago.`
      : `Updated ${differenceInDays} days ago.`;
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(projects.length / postcardsPerPage);

  const handleSearch = (query: any) => {
    setSearch(query);
  };

  const handleSort = (query: any) => {
    setSort(query);
  };

  return (
    <div className="bg-gray-200 mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 ">
      <div className="flex justify-between text-center gap-3 ">
        <SearchBar
          className="flex-grow mr-1"
          onSearch={(query: any) => {
            handleSearch(query);
          }}
        />
        <SortDropdown onSortChange={(query: any) => handleSort(query)} />
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 xl:gap-x-8">
        {currentPostcards.map((project: any) => (
          <div key={project.id} className="mt-6 bg-white rounded-lg ">
            <Link to={`/post/${project.id}`}>
              <div className="w-100 rounded overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in latest">
                <div className="px-6 py-4">
                  <div className="font-bold text-2xl mb-4 text-blue-700 flex justify-between">
                    {project.name}
                    <p className="text-base font-mono">
                      {" "}
                      {project.bidCount} Bids{" "}
                    </p>
                  </div>

                  <div className="flex flex-col justify-between">
                    <div className="text-gray-800 text-base font-mono">
                      Budget ${project.billingRate}/hr
                    </div>
                    <p className="text-gray-800 text-base">
                      {project.description}
                    </p>
                    <div className="flex-column">
                      <div className="flex justify-between gap-3">
                        <p className="text-gray-800 text-base font-mono">
                          Location{" "}
                          <span className="font-mono text-blue-900">
                            {project.location ? project.location : "Remote"}
                          </span>
                        </p>
                        <p className="font-mono"> {project.duration} hours </p>

                        <div className="text-blue-600 font-mono">
                          #{project.technology}
                        </div>
                      </div>
                    </div>
                    <div className="text-sm font-mono text-right mt-2">
                      {lastUpdated(project)}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}

        <div className="flex justify-center">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              className={`mx-1 px-4 py-2 rounded ${
                page === currentPage
                  ? "bg-blue-500 text-white"
                  : "bg-white text-black"
              }`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
