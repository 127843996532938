import React from "react";
import { capitalizeFirstLetter } from "../summary-profile/SummaryProfile";

const UserProfile = ({ user, isEditMode, handleImageUpload }: any) => {
  return (
    <div className="flex flex-col p-2 bg-white text-black h-full ">
      {" "}
      <img
        alt=""
        src={
          user.profilePic ??
          "https://thedevelopersworld-profile-img.s3.ap-south-1.amazonaws.com/blank-profile-picture.jpg"
        }
        className="h-52 w-96 border"
      />
      {isEditMode ? (
        <div>
          <input type="file" onChange={handleImageUpload} />{" "}
          <p className="text-xs text-red-500">
            {" "}
            image size not greater than 2mb
          </p>
        </div>
      ) : null}
      <div className="mt-2 p-4">
        <h2 className="text-lg font-semibold text-gray-900">
          {capitalizeFirstLetter(user?.name)}
        </h2>
        <p className="text-sm text-gray-500">{user?.email}</p>
      </div>
    </div>
  );
};

export default UserProfile;
