import apiClient from "./api-client";
import { User } from "./user-service";

export interface Bid {
  id?: number;
  projectId: number;
  bidAmount: number;
  bidDescription: string;
  user: User;
}

class BidService {
  getBids() {
    return apiClient.get<Bid[]>("bid/");
  }

  deleteBid(id: number) {
    return apiClient.delete("/bid/" + id);
  }

  createBid(bid: Bid) {
    return apiClient.post("/bid/", bid);
  }

  updateBid(bid: Bid) {
    return apiClient.patch("/project/" + bid.id, bid);
  }
}

export default new BidService();
