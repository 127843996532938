import { createSlice } from "@reduxjs/toolkit";

const contactusSlice = createSlice({
  name: "contactus",
  initialState: null,
  reducers: {
    save: (state, action) => {
      return action.payload;
    },
  },
});

export default contactusSlice.reducer;
export const { save } = contactusSlice.actions;
