import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import userService from "../../services/user-service";
import { addUser } from "../../slices/userSlice";
import { useDispatch } from "react-redux";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const Register = () => {
  const [error, setError] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("");

  const handleSelectRole = (event: any) => {
    setSelectedRole(event.target.value);
  };

  const onsubmit = async (data: FormData) => {
    try {
      const result = await userService.createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.email,
        password: data.password,
        role: selectedRole,
      });
      if (result.status === 200) {
        dispatch(
          addUser({
            ...result.data.user,
          })
        );
        navigate("/");
      }
    } catch (err: any) {
      setError(err.response.data.message);
      navigate("/register");
    }
  };

  return (
    <div className="flex h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-black text-white">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm p-4">
        <img
          className="mx-auto h-10 w-auto mt-12"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-300">
          Sign up to your account
        </h2>
        <p className="justify-center text-red-500 font-semibold text-center">
          {error}
        </p>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            onSubmit={handleSubmit(onsubmit)}
            method="POST"
          >
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium leading-6 text-gray-300"
              >
                First name
              </label>
              <div className="mt-2">
                <input
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "first name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "min length s 3",
                    },
                  })}
                  placeholder="First Name"
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="firstName"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors?.firstName && (
                <p className="text-red-500 text-sm italic">
                  {errors.firstName.message}
                </p>
              )}
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-6 text-gray-300"
              >
                Last name
              </label>
              <div className="mt-2">
                <input
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: "last name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "min length s 3",
                    },
                  })}
                  placeholder="Last Name"
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="lastName"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors?.lastName && (
                  <p className="text-red-500 text-sm italic">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-300"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  })}
                  placeholder="Email address"
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm italic">
                    {errors.email.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-6 text-gray-300"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "password is required",
                    },
                    minLength: {
                      value: 6,
                      message: "min length is 6",
                    },
                  })}
                  placeholder="Password"
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.password && (
                  <p className="text-red-500 text-sm italic">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <label
                className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2"
                htmlFor="grid-state"
              >
                Role
              </label>
              <div className="relative">
                <select
                  value={selectedRole}
                  onChange={handleSelectRole}
                  className="mb-4 block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                >
                  <option value="">Select a role</option>
                  <option value="CLIENT">CLIENT</option>
                  <option value="FREELANCER">FREELANCER</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Register
              </button>
            </div>
            <div className="mt-3">
              <Link
                to="/login"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Go back to Login page
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
