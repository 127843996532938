import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import projectReducer from "../slices/projectSlice";
import bidReducer from "../slices/bidSlice";
import contactusReducer from "../slices/contactusSlice";

const appStore = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    bid: bidReducer,
    contactus: contactusReducer,
  },
});

export default appStore;
