import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Top Talent at Your Fingertips",
    description:
      "Whether you're looking for innovative solutions, expert advice, or project execution, our curated pool of skilled freelancers is here to help you achieve your goals efficiently and effectively. ",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Transform Your Vision into Reality",
    description:
      "Our platform connects you with top professionals who are skilled in turning concepts into tangible results. Whether you need a creative design, technical development, or strategic consultation, our talented freelancers are ready to collaborate and turn your vision into a successful reality.",
    icon: LockClosedIcon,
  },
  {
    name: "Freelance Excellence Meets Your Unique Needs",
    description:
      "Experience unparalleled freelance talent tailored to your specific requirements. Our platform brings together top-tier professionals with diverse skills to address your unique project needs.",
    icon: ArrowPathIcon,
  },
  {
    name: "Transformative Freelancing for Every Industry",
    description:
      "Unlock the power of freelance expertise across all sectors with our versatile platform and team. We connect you with top professionals who bring transformative solutions to any industry, from tech and design to marketing and beyond.",
    icon: FingerPrintIcon,
  },
];

export default function Features() {
  return (
    <div className="bg-black py-24 sm:py-32 text-gray-300">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-400">
            Transformative Freelancing for Every Industry – Find Your Expert
            Today
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl">
            Elevate Your Business
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            "Creative Solutions, Professional Results – Partner with Our
            Freelancers and build your dream project along with us."
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-200">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-300">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
