import React, { useRef } from "react";
import Features from "../features/Features";
import { Home } from "../home/Home";
import Pricing from "../pricing/Pricing";
import Stats from "../stats/Stats";
import Testimonials from "../testimonials/Testimonials";
import Footer from "../footer/Footer";

const Body = ({ featuresRef, pricingRef, statsRef, testimonialsRef }) => {
  const backToTopButtonRef = useRef(null);

  return (
    <div>
      <Home />
      <div ref={featuresRef}>
        <Features />
      </div>
      <div ref={pricingRef}>
        <Pricing />
      </div>
      <div ref={statsRef}>
        <Stats />
      </div>
      <div ref={testimonialsRef}>
        <Testimonials />
      </div>
      <button
        id="backToTopButton"
        ref={backToTopButtonRef}
        onClick={handleClick}
      >
        {" "}
        ⬆️{" "}
      </button>
      <Footer />
    </div>
  );
};

window.onscroll = function () {
  var button = document.getElementById("backToTopButton");
  if (button) {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      button.style.display = "block";
    } else {
      button.style.display = "none";
    }
  }
};

const handleClick = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};
export default Body;
