import React from "react";
import { useForm } from "react-hook-form";
import userService, { Experience } from "../../../services/user-service";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { updateUserExperience } from "../../../slices/userSlice";

type FormData = {
  title: string;
  company: string;
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  isCurrentCompany: boolean;
  summary: string;
};

const AddExperience = ({ user, handleCancel, handleSave }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormData>();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from(
    { length: 50 },
    (_, i) => new Date().getFullYear() - i
  );

  const convertToExperienceData = (formData: FormData): Experience => {
    return {
      company: formData.company,
      title: formData.title,
      summary: formData.summary,
      startMonth: formData.startMonth,
      startYear: formData.startYear,
      endMonth: formData.isCurrentCompany ? "present" : formData.endMonth,
      endYear: formData.isCurrentCompany ? "" : formData.endYear,
      isCurrentCompany: formData.isCurrentCompany,
      userName: user.email,
    };
  };

  const onsubmit = async (data: FormData) => {
    try {
      const result = await userService.saveExperience(
        convertToExperienceData(data)
      );
      if (result.status === 200) {
        alert("Profile updated successfully");
        dispatch(updateUserExperience(result.data.experiences));
        handleSave();
      } else {
        alert("Profile update failed");
      }
    } catch (err) {
      alert("Profile update failed" + err);
    }
  };

  return (
    <div className=" w-full h-full mb-4 bg-white text-black sm:col-span-1 md:col-span-3 lg:col-span-6">
      <form
        className="space-y-6"
        onSubmit={handleSubmit(onsubmit)}
        method="POST"
      >
        <div className="grid  sm:col-span-1 md:col-span-3 ">
          <div className="m-2 w-2/3 grid sm:col-span-1 md:col-span-3 ">
            <label htmlFor="title" className=" text-sm font-medium leading-6 ">
              Title
            </label>
            <div className="mt-2 ">
              <input
                {...register("title", {
                  required: {
                    value: true,
                    message: "title is required",
                  },
                  minLength: {
                    value: 3,
                    message: "min length of the title is 3",
                  },
                })}
                placeholder="Title"
                id="title"
                name="title"
                type="text"
                autoComplete="title"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.title && (
              <p className="text-red-500 text-sm italic">
                {errors.title.message}
              </p>
            )}
          </div>
          <div className="m-2 w-2/3 grid sm:col-span-1 md:col-span-3 ">
            <label
              htmlFor="company"
              className=" text-sm font-medium leading-6 "
            >
              Company
            </label>
            <div className="mt-2 ">
              <input
                {...register("company", {
                  required: {
                    value: true,
                    message: "company is required",
                  },
                  minLength: {
                    value: 3,
                    message: "min length of the company is 3",
                  },
                })}
                placeholder="Company"
                id="company"
                name="company"
                type="text"
                autoComplete="company"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            {errors?.company && (
              <p className="text-red-500 text-sm italic">
                {errors.company.message}
              </p>
            )}
          </div>
        </div>
        <div className="grid  sm:col-span-1 md:col-span-3 ">
          <div className="w-100 m-2">
            <label htmlFor="start" className=" text-sm font-medium leading-6 ">
              Start
            </label>
            <div className="mt-2 ">
              <div className="flex space-x-4">
                <div className="relative">
                  <select
                    {...register("startMonth", {
                      required: {
                        value: true,
                        message: "startMonth is required",
                      },
                    })}
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="">Select Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  {errors?.startMonth && (
                    <p className="text-red-500 text-sm italic">
                      {errors.startMonth.message}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <select
                    {...register("startYear", {
                      required: {
                        value: true,
                        message: "startYear is required",
                      },
                    })}
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {errors?.startYear && (
              <p className="text-red-500 text-sm italic">
                {errors.startYear.message}
              </p>
            )}
          </div>
          <div className="w-100 m-2">
            <label htmlFor="start" className=" text-sm font-medium leading-6 ">
              End
            </label>

            <div className="mt-2 ">
              <div className="flex space-x-4">
                <div className="relative">
                  <select
                    {...register("endMonth")}
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="">Select Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="relative">
                  <select
                    {...register("endYear")}
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid sm:col-span-1 md:col-span-3  items-center p-2">
          <div>
            <input
              id="isCurrentCompany"
              type="checkbox"
              {...register("isCurrentCompany", {})}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <label htmlFor="isCurrentCompany" className="ml-2 text-gray-700">
              I am currently working here
            </label>
          </div>
        </div>
        <div className="w-2/3 grid sm:col-span-1 md:col-span-3  items-center p-2">
          <label htmlFor="summary" className="text-sm font-medium leading-6 ">
            Summary
          </label>
          <div className="mt-2 ">
            <textarea
              {...register("summary", {
                required: {
                  value: true,
                  message: "summary is required",
                },
              })}
              placeholder="Summary"
              id="summary"
              name="summary"
              autoComplete="summary"
              className="block w-full h-64  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {errors?.summary && (
            <p className="text-red-500 text-sm italic">
              {errors.summary.message}
            </p>
          )}
        </div>
        <div className="grid m-2 sm:col-span-1 md:col-span-3  justify-end mt-4">
          <div>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddExperience;
