import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "./user-profile/UserProfile";
import SummaryProfile from "./summary-profile/SummaryProfile";
import Experience from "./experience/Experience";
import Qualifications from "./qualifications/Qualifications";
import { set } from "react-hook-form";
import userService from "../../services/user-service";
import {
  addUser,
  updateUserProfile,
  updateUserProfileImage,
} from "../../slices/userSlice";

const ProfilePage = () => {
  const user = useSelector((store: any) => store.user);
  const userDetails = useSelector((store: any) => store.user.userDetails);
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [headline, setHeadline] = React.useState(userDetails?.headline);
  const [description, setDescription] = React.useState(
    userDetails?.description
  );
  const [image, setImage] = React.useState(user.picture);

  const handleTextChange: any = (e: any) => {
    setHeadline(e.target.value);
  };

  const handletextAreaChange: any = (e: any) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    if (!userDetails) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
      setHeadline(userDetails?.headline);
      setDescription(userDetails?.description);
    }
  }, [userDetails]);

  const handleClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSave = async () => {
    if (!headline || headline.length < 10) {
      alert("Please enter at least 10 characters for headline");
      return;
    }
    if (!description || description.length < 100) {
      alert("Please enter at least 30 characters for headline");
      return;
    }
    try {
      const result = await userService.updateUserDetails({
        userName: user.email,
        headline: headline,
        description: description,
        pictureUrl: user.picture,
      });
      if (result.status === 200) {
        dispatch(updateUserProfile(result.data));
        const formData = new FormData();
        formData.append("file", image);
        formData.append("username", user.email);
        const imageResult = await userService.uploadImage(formData);
        if (imageResult.status === 200) {
          console.log(imageResult);
          const imageURL = imageResult.data;
          dispatch(updateUserProfileImage(imageURL));
          setIsEditMode(!isEditMode);
          alert("Profile updated successfully");
        } else {
          console.log(imageResult);
          alert("Profile update failed");
        }
      } else {
        alert("Profile update failed");
      }
    } catch (err) {
      alert("Profile update failed" + err);
    }
  };

  const handleImageUpload = (e: any) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="mt-24 h-full min-h-screen">
      <header className="bg-gray-200 shadow">
        <div className="mx-12 py-6 flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded align-right"
            onClick={handleClick}
          >
            {isEditMode ? "Cancel Edit" : "Edit Profile"}
          </button>
          {isEditMode && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded align-right"
              onClick={handleSave}
            >
              Save
            </button>
          )}
        </div>
      </header>
      <main className="bg-gray-200 h-full min-h-screen sm:col-span-1 md:col-span-3 lg:col-span-6">
        <div className="bg-gray-200 h-full ">
          <div className="rounded-md  grid sm:col-span-1 md:col-span-3 lg:col-span-6">
            <div className=" w-100  m-4 grid  sm:col-span-1 md:col-span-3 ">
              <UserProfile
                user={user}
                isEditMode={isEditMode}
                handleImageUpload={handleImageUpload}
              />
            </div>
            <div className=" m-4 grid w-100 sm:col-span-1 md:col-span-3 ">
              <SummaryProfile
                user={user}
                isEditMode={isEditMode}
                handleTextChange={handleTextChange}
                handletextAreaChange={handletextAreaChange}
                headline={headline}
                description={description}
              />
            </div>
          </div>
        </div>

        <div className="grid sm:col-span-1 md:col-span-3 border border-solid m-4 h-full border-gray-200 bg-gray-200">
          <Experience user={user} />
        </div>
        {/* <div className="flex h-96 border border-solid border-gray-200 bg-gray-200">
          <Qualifications />
        </div> */}
      </main>
    </div>
  );
};

export default ProfilePage;
