import React, { useState } from "react";

const SortDropdown = ({ onSortChange }: any) => {
  const [sort, setSort] = useState("latest");

  const handleChange = (event: any) => {
    setSort(event.target.value);
    onSortChange(event.target.value);
  };

  return (
    <select
      value={sort}
      onChange={handleChange}
      className="mt-1 block py-2 px-3 border cursor-pointer border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    >
      <option value="latest">Latest</option>
      <option value="highestBid">Highest Bids</option>
      <option value="lowestBid">Lowest Bids</option>
      <option value="highestHours">Highest Hours</option>
      <option value="lowestHours">Lowest Hours</option>
    </select>
  );
};

export default SortDropdown;
