import React, { useEffect, useState } from "react";
import projectService from "../../../services/project-service";
import { useDispatch, useSelector } from "react-redux";
import { addProject } from "../../../slices/projectSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { addUser } from "../../../slices/userSlice";

type FormData = {
  name: string;
  description: string;
  budget: string;
  duration: string;
  technology: string;
  billingRate: string;
  location: string;
  projectType: string;
  timezone: string;
  message: string;
};

const PostProject: React.FC<any> = () => {
  const [error, setError] = React.useState("");
  const user = useSelector((store: any) => store.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [selectedTech, setSelectedTech] = useState("");
  const [projectType, setProjectType] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectChange = (event: any) => {
    setSelectedTech(event.target.value);
  };
  const handleProjectTypeChange = (event: any) => {
    setProjectType(event.target.value);
  };

  const onsubmit = async (data: FormData) => {
    if (user && !user.userName && localStorage.getItem("user")) {
      let storedValue: any = localStorage.getItem("user");
      try {
        let credential_response = JSON.parse(storedValue);
        dispatch(addUser(credential_response));
      } catch (error) {
        console.error("Error parsing JSON from local storage", error);
      }
    } else {
      setError("Please login as a Client to post a project");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
      return;
    }
    try {
      const result = await projectService.createProject({
        name: data.name,
        description: data.description,
        location: data.location,
        timezone: data.timezone,
        billingRate: data.budget,
        duration: data.duration,
        technology: selectedTech ? selectedTech : data.technology,
        message: data.message,
        projectType: projectType,
        userId: user.id,
        bidCount: 0,
      });
      if (result.status === 200) {
        alert("Project created successfully");
        dispatch(addProject(result.data));
        navigate("/posts");
      } else {
        alert("Something went wrong, please try again later");
        setError("Something went wrong, please try again");
      }
    } catch (err: any) {
      alert("Something went wrong, please try again later");
      setError(err.response.data.message);
      return;
    }
  };

  return (
    <div className="bg-gray-100 text-white h-full w-full flex justify-center mt-2">
      <div className="w-2/3 h-2/3 bg-white m-34 text-black md:p-4 sm:p-4 items-center border border-gray-300 rounded-xlg">
        <form
          onSubmit={handleSubmit(onsubmit)}
          className="w-100 mx-2 mt-24 mb-12"
        >
          <h1 className="font-semibold heading text-blue-800  text-center text-nowrap">
            Post your project
          </h1>
          <div className="mt-2 grid w-100 sm:gap-x-1 sm:gap-y-1 md:gap-x-3 md:gap-y-8  h-full mb-4 pb-4">
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="title" className="block mb-1">
                Title of the project
                <span className="text-sm">(min 15 words)</span>:
              </label>
              <textarea
                {...register("name", {
                  required: {
                    value: true,
                    message: "Title of the project is required",
                  },
                  minLength: {
                    value: 15,
                    message: "min length is 15",
                  },
                })}
                placeholder="Title of the project"
                id="name"
                name="name"
                className="w-full border border-gray-300 px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
              <span className="text-red-500">
                {errors?.name && errors.name.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="description" className="block mb-1">
                Description <span className="text-sm">(min 100 words)</span>:
              </label>
              <textarea
                {...register("description", {
                  required: {
                    value: true,
                    message: "Description is required",
                  },
                  minLength: {
                    value: 50,
                    message: "min length is 50",
                  },
                })}
                placeholder="Describe your project"
                id="description"
                name="description"
                className="w-full  border-gray-300 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 h-48 text-black"
              />
              <span className="text-red-500">
                {errors?.description && errors.description.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="title" className="block mb-1">
                *Location:
              </label>
              <input
                {...register("location", {
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                })}
                placeholder="Location"
                type="text"
                id="location"
                name="location"
                className="w-full border border-gray-300 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
              <span className="text-red-500">
                {errors?.location && errors.location.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="title" className="block mb-1">
                *Timezone:
              </label>
              <input
                {...register("timezone", {
                  required: {
                    value: true,
                    message: "Timezone is required",
                  },
                })}
                placeholder="Timezone"
                type="text"
                id="timezone"
                name="timezone"
                className="w-full border border-gray-300 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
              <span className="text-red-500">
                {errors?.timezone && errors.timezone.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="budget" className="block mb-1">
                *Budget <span className="text-sm">($/hr)</span>:
              </label>
              <input
                {...register("budget", {
                  required: {
                    value: true,
                    message: "Budget is required",
                  },
                  validate: (value) =>
                    parseFloat(value) > 0 || "Budget must be a positive number",
                })}
                placeholder="Budget"
                type="number"
                id="budget"
                className="w-full border border-gray-300 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
              <span className="text-red-500">
                {errors?.budget && errors.budget.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="duration" className="block mb-1">
                *Duration <span className="text-sm">(hours)</span>:
              </label>
              <input
                {...register("duration", {
                  required: {
                    value: true,
                    message: "Duration is required",
                  },
                  validate: (value) =>
                    parseFloat(value) > 0 ||
                    "Duration must be a positive number",
                })}
                placeholder="Duration"
                type="number"
                id="duration"
                className="w-full  border-gray-300 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
              <span className="text-red-500">
                {errors?.duration && errors.duration.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <select
                value={selectedTech}
                onChange={handleSelectChange}
                className="mb-4 block cursor-pointer appearance-none w-full bg-white border border-gray-200 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
              >
                <option value="">Select a technology</option>
                <option value="Vue">Java</option>
                <option value="React">React</option>
                <option value="Angular">Angular</option>
                <option value="Vue">Vue</option>
                <option value="Aws">AWS</option>
                <option value="Azure">Azure</option>
                <option value="GoogleCloud">Google Cloud</option>
                <option value="Others">Others</option>
              </select>
              {selectedTech === "Others" && (
                <>
                  <label htmlFor="duration" className="block mb-1">
                    <span className="text-sm">Others value is required </span>:
                  </label>
                  <input
                    {...register("technology", {
                      required: {
                        value: true,
                        message: "others value is required",
                      },
                    })}
                    type="text"
                    id="technology"
                    name="technology"
                    className="w-full  border-gray-300 px-3 py-4 mb-4 border rounded-md focus:outline-none focus:border-blue-500 text-black"
                  />
                </>
              )}
              <span className="text-red-500">
                {errors?.technology && errors.technology.message}
              </span>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <select
                value={projectType}
                onChange={handleProjectTypeChange}
                className="mb-4 cursor-pointer border-gray-300 block appearance-none w-full bg-white border  text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
              >
                <option value="">Project Type</option>
                <option value="fixedprice">Fixed Price</option>
                <option value="hourly">Hourly</option>
              </select>
            </div>
            <div className="mb-4 mt-4 sm:col-span-1 md:col-span-3 lg: col-span-6">
              <label htmlFor="description" className="block mb-1">
                Message:
              </label>
              <textarea
                {...register("message", {})}
                placeholder="Message to the developers"
                id="message"
                name="message"
                className="w-full  border-gray-300 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 text-black"
              />
              <span className="text-red-500">
                {errors?.message && errors.message.message}
              </span>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <button
              type="submit"
              className=" bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Post a Project
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostProject;
