export default function Testimonials() {
  return (
    <section className="relative isolate overflow-hidden bg-black text-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-5 bg-black" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-black text-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
            <p>
              "Catalyst of Innovation: Meet Our CEO,At the helm of The
              Developers World, driving our vision of technological excellence
              and customer-centricity, is our esteemed CEO, With a passion for
              innovation and a relentless pursuit of excellence, brings a wealth
              of experience and expertise to our organization.
            </p>
          </blockquote>
          <figcaption className="mt-10">
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold text-gray-200"></div>
              <svg
                viewBox="0 0 2 2"
                width={3}
                height={3}
                aria-hidden="true"
                className="fill-black text-gray-200"
              ></svg>
            </div>
          </figcaption>
        </figure>
      </div>
    </section>
  );
}
